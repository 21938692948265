import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ArticleCarousel from '../components/ArticleCarousel';
import { useArticlesByCat } from '../hooks/useArticlesByCat';
import PagesHero from '../components/PagesHero';
import Border from '../components/Border';
import Newsletter from '../components/Newsletter';

import newsletterS from '../images/newsletterServices.jpg';
import newsletterL from '../images/newslettersServ-large.jpg';

import '../styles/3_pages/news.scss';

const News = ({ location }) => {
  useEffect(() => {
    if (location.state?.scrollTo) {
      scroller.scrollTo(location.state.scrollTo, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -80,
      });
    }
  }, []);
  const {
    latestClaims, insurance, debtHelp, creditProducts, utilities,
  } = useArticlesByCat();
  return (
    <Layout location={location}>
      <SEO title={process.env.SITE_TITLE} />
      <section className="news">
        <PagesHero news location={location} />
        <Border news />
        <ArticleCarousel id="latestClaims" title="Latest Claims" data={latestClaims} location={location} />
        <Border news />
        <ArticleCarousel id="utilities" title="Utilities" data={utilities} location={location} />
        <Border news />
        <ArticleCarousel id="creditProducts" title="Credit Products" data={creditProducts} location={location} />
        <Border news />
        <ArticleCarousel id="insurance" title="Insurance" data={insurance} location={location} />
        <Border news />
        <ArticleCarousel id="debtHelp" title="Debt Help" data={debtHelp} location={location} />
        <Border news />
        <Newsletter
          location={location}
          imageSmall={newsletterS}
          imageLarge={newsletterL}
          title="FREE Newsletter "
          paragraph="Sign up to receive email updates on the topics that matter to you. Remember, we’ll only send offers based on your preferences."
        />
      </section>
    </Layout>
  );
};

News.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({
      scrollTo: PropTypes.string,
    }),
  }).isRequired,
};

export default News;
