import React from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';

import './styles.scss';

const NEXT = 'NEXT';
const PREV = 'PREV';
const SLIDENEXT = 'SLIDE_NEXT';
const SLIDEPREV = 'SLIDE_PREV';

const SPEED = 500;

const getorder = ({ index, pos, numItems }) => {
  const orderPos = index - pos + 1;
  if (orderPos < 0) {
    return numItems - Math.abs(index - pos) + 1;
  }
  if (orderPos === numItems) {
    return 0;
  }
  return orderPos;
};

const initialState = { pos: 1, sliding: false, dir: NEXT };

function reducer(state, { type, numItems }) {
  switch (type) {
    case 'reset':
      return initialState;
    case PREV:
      return {
        ...state,
        sliding: false,
        pos: state.pos === 0 ? numItems - 1 : state.pos - 1,
      };
    case NEXT:
      return {
        ...state,
        sliding: false,
        pos: state.pos === numItems - 1 ? 0 : state.pos + 1,
      };
    case SLIDENEXT:
      return {
        ...state,
        dir: NEXT,
        sliding: true,
      };
    case SLIDEPREV:
      return {
        ...state,
        dir: PREV,
        sliding: true,
      };
    default:
      return state;
  }
}

const Carousel = ({ children, id }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const numItems = React.Children.count(children);

  const slideTo = (dir) => {
    dispatch({ type: dir === NEXT ? SLIDENEXT : SLIDEPREV });
    setTimeout(() => {
      dispatch({ type: dir, numItems });
    }, SPEED);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => slideTo(NEXT),
    onSwipedRight: () => slideTo(PREV),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const animateDirection = state.dir === NEXT ? '-200%' : '0';

  return (
    <div className="carousel">
      <div
        id={id}
        className="carousel__handler"
        {...handlers}
      >
        <div className="carousel__mask">
          <div className="carousel__wrapper">
            <div
              className="carousel__container"
              style={{
                opacity: `${state.sliding ? '0.7' : '1'}`,
                transform: `translateX(${state.sliding ? animateDirection : '-100%'})`,
                transition: `${state.sliding ? `transform ${SPEED}ms` : ''}`,
              }}
            >
              {children.map((child, index) => {
                const order = getorder({ index, pos: state.pos, numItems });
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={`${id}-${index}`}>
                    {
                      order === 0 && (
                        <div
                          className="carousel__carousel-slot"
                          style={{
                            order: numItems,
                          }}
                        >
                          {child}
                        </div>
                      )
                    }
                    {
                      order === numItems - 1 && (
                        <div
                          className="carousel__carousel-slot"
                          style={{
                            order: -1,
                          }}
                        >
                          {child}
                        </div>
                      )
                    }
                    <div
                      className="carousel__carousel-slot"
                      style={{
                        order,
                      }}
                    >
                      {child}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
        <div className="carousel__slide-button">
          <button
            className="carousel__slide-button__button carousel__slide-button__button--next"
            type="button"
            disabled={state.sliding}
            onClick={() => slideTo(NEXT)}
            float="left"
          >
            Prev
          </button>
          <button
            className="carousel__slide-button__button carousel__slide-button__button--prev"
            type="button"
            onClick={() => slideTo(PREV)}
            disabled={state.sliding}
            float="right"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

Carousel.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Carousel;
