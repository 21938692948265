import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Link } from 'gatsby';

import Carousel from '../Carousel';

import './styles.scss';

const ArticleCarousel = ({
  location, title, data, id,
}) => {
  const mmbData = data.filter(mmbArticle => mmbArticle.brand === 'mymoneyback.co.uk');

  return (
    <div className="article-carousel" id={id}>
      <div className="article-carousel__header">
        <h2 className="article-carousel__header__title">
          { title }
        </h2>
        <p className="article-carousel__header__updated">
          Updated
          {' '}
          {dayjs(data[0].lastUpdated).format('dddd d MMMM YYYY')}
        </p>
      </div>
      <div className="article-carousel__carousel-container">
        <Carousel id={title.toLowerCase().replace(' ', '')}>
          {
            mmbData.map(article => (
              <Link
                className="article-preview__link"
                to={`/${article.fields.slug}/${location.search}`}
                key={`${title}-${article.title}`}
              >
                <div className="article-preview">
                  <div className="article-preview__thumbnail">
                    <img src={article.previewImage.fluid.src} alt={article.title} />
                  </div>
                  <h3 className="article-preview__title">{article.title}</h3>
                  <h3 className="article-preview__description">
                    {article.metaDescription}
                  </h3>
                </div>
              </Link>
            ))
          }
        </Carousel>
      </div>
    </div>
  );
};

ArticleCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      lastUpdated: PropTypes.string,
      brand: PropTypes.string,
      metaDescription: PropTypes.string.isRequired,
      fields: PropTypes.shape({
        slug: PropTypes.string,
      }),
    }),
  ).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
};

export default ArticleCarousel;
