import { useStaticQuery, graphql } from 'gatsby';

export const useArticlesByCat = () => {
  const { allContentfulArticle: { edges: newsArticles } } = useStaticQuery(
    graphql`
    query AllContentfulArticle {
      allContentfulArticle(sort: {fields: lastUpdated, order: DESC}) {
        edges {
          node {
            id
            title
            brand
            category
            lastUpdated
            metaDescription
            fields {
              categoryId
              categoryPath
              slug
            }
            previewImage {
              fluid {
                src
              }
            }
          }
        }
      }
    }
    `,
  );

  const articlesByCat = {};

  // Assign each news page to its category
  newsArticles.forEach((edge) => {
    const { categoryId } = edge.node.fields;

    if (!(categoryId in articlesByCat)) {
      articlesByCat[categoryId] = [];
    }

    articlesByCat[categoryId].push(edge.node);
  });


  return articlesByCat;
};

export default useArticlesByCat;
